:root {
  --dark-bg-color: #2e2d2f;
  --light-bg-color: #fff;
  --primary: #f62b54;
  --secondary: #12c2e9;
}

* {
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.5s ease;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}
