.image-gallery-right-nav::before {
  color: #fff;
  line-height: 0.7;
  text-shadow: 0 1px 1px var(--secondary);
  transition: color 0.1s ease-out;
  padding: 5px;
  border-radius: 4px;
  content: '›';
  font-size: 100px;
}

.image-gallery-left-nav::before {
  color: #fff;
  line-height: 0.7;
  text-shadow: 0 1px 1px var(--secondary);
  transition: color 0.1s ease-out;
  content: '‹';
  font-size: 100px;
}

.image-gallery-right-nav:hover::before,
.image-gallery-right-nav:focus::before {
  color: var(--secondary);
}

.image-gallery-left-nav:hover::before,
.image-gallery-left-nav:focus::before {
  color: var(--secondary);
}

.image-gallery-slides {
  border-radius: 4px;
}

.image-gallery-thumbnail.active {
  border: 4px solid var(--secondary);
  border-radius: 4px;
}

a.image-gallery-thumbnail:not(.active) img {
  border-radius: 4px;
  transition: border-radius 0.2s ease-out;
}

.image-gallery-bullets {
  display: none;
}
